@import '../_variables.scss';

.ascensionPage {
  @media all and (max-width: $break-point-xs) {
    padding-top: 80px;
  }

  .heroBg {
    width: 100%;
    padding-top: 57%;
    position: relative;

    @media all and (max-width: $break-point-xs) {
      padding-top: 85%;
    }
  }

  .hero {
    position: relative;
    padding-top: $spacingBig;
    
    @media all and (max-width: $break-point-xs) {
      padding-top: $spacingBig;
    }

    h2 {
      font-size: 28px;
      color: $secondaryColor;
      margin-bottom: 18px;
    }

    p {
      font-size: $fontSizeBasePlus;
      line-height: 32px;
      max-width: 640px;
      margin: 20px auto 0;
      color: $secondaryColor;

      span {
        color: #00B6BD;
      }
    }

    button {
      margin: $spacingBig auto 0;
      height: 50px;
    }

    .contactsWrap {
      display: flex;
      justify-content: center;
      margin-top: $spacingBasePlus;

      .contacts {
        span {
          @media all and (max-width: $break-point-xs) {
            display: flex;
          }
        }
      }
    }
  }

  p {
    color: $secondaryColor;
  }

  button {
    font-weight: bold;
    font-size: 16px;
    padding: 12px 23px;
    background: white;
    box-shadow: 0 0 18px #00F6FF;
    border-radius: 5px;
    color: #00B6BD;
    max-width: 240px;

    &:disabled {
      &:before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .collectionsSection {
    margin-top: $spacingHuge;
    margin-bottom: $spacingHuge;
    
    @media all and (max-width: $break-point-xs) {
      margin-bottom: $spacingBasePlus;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media all and (max-width: 576px) {
        flex-wrap: wrap;
      }
    }

    .collectionCard {
      border-radius: 5px;
      overflow: hidden;
      width: calc(100% / 2 - 10px);

      @media all and (max-width: $break-point-xs) {
        width: 100%;
        margin-bottom: 30px;
      }

      .imageWrap {
        position: relative;

        & > div {
          display: block !important;
        }

        .text {
          position: absolute;
          color: #FFFFFF;
          font-size: $fontSizeBasePlus;
          font-family: 'Trajan Pro 3';
          font-weight: 500;
          text-align: center;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);

          .name {
            font-size: 48px;
          }
        }
      }

      .controls {
        padding: 25px;
        background-color: $thirdColor;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @media all and (max-width: $break-point-xs) {
          font-size: $fontSizeSmall;
        }

        &.spaceBetween {
          justify-content: space-between;
        }

        span {
          text-transform: uppercase;
        }

        .openseaLink {
          cursor: pointer;
          position: relative;

          &:before {
            content: '';
            display: block;
            background-color: rgba(255, 255, 255, 1);
            margin: 0 auto;
            transition: all 0.2s linear;
            width: 30px;
            height: 30px;
            mask: url(/images/icons/opensea.svg) no-repeat center;
            mask-size: 30px;
            position: absolute;
            left: -43px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .friendsSection {
    padding-bottom: $spacingBase;

    h2 {
      font-size: 30px;
      color: $secondaryColor;
      text-align: center;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: $spacingBig;

      @media all and (max-width: $break-point-xs) {
        margin-bottom: 0;

        .imageWrap {
          margin-bottom: $spacingBig;
        }
      }
    }
  }
}
